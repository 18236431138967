import React, {useState} from "react"
import {Box, ButtonBase, Fade, Modal, Paper, Typography} from "@mui/material";
import {MailOutline} from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import mailQr from "../../../assets/mailQr.png";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            background: '#000000',
            borderRadius: '12px',
            width: '52px',
            height: '52px',
            color: 'white',
        },
        inverseButton: {
            background: 'white',
            color: 'black',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    })
)

type MailButtonProps = { inverse?: boolean };
export const MailButton = ({inverse}: MailButtonProps) => {
    const classes = useStyles();
    const inverseButtonStyle = [classes.button, classes.inverseButton].join(' ');
    const [visibility, setVisibility] = useState(false);
    const showMail = () => {
        setVisibility(true)
    }
    const hideContact = () => {
        setVisibility(false)
    }

    return <>
        <ButtonBase className={inverse ? inverseButtonStyle : classes.button} onClick={showMail} >
            <MailOutline fontSize={'large'} />
        </ButtonBase>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={visibility}
            onClose={hideContact}
            closeAfterTransition
            className={classes.modal}
        >
            <Fade in={visibility}>
                <Box component={Paper} p={4} pt={2} borderRadius={5}>
                    <Box textAlign='center'>
                    <img src={mailQr} style={{
                        maxWidth: '100vw',
                        maxHeight: '1000px'
                    }} alt={'QR'}/>
                    </Box>
                    <Box textAlign='center'>
                        <Typography
                            component={'a'}
                            href={'mailto:kristina@shulgin.pro'}
                            fontSize={20}
                            color={'black'}
                            fontWeight={800}
                            fontFamily={'Montserrat'}
                        >
                            kristina@shulgin.pro
                        </Typography>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </>
}
